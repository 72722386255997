/* eslint-disable */
import CloudFun, { Model } from "@cloudfun/core";
import model from '@/models/index'

class UploadAdapter {
    xhr: XMLHttpRequest;
    loader: any;

    constructor(loader: any) {
        // The file loader instance to use during the upload.
        this.xhr = new XMLHttpRequest();
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then((file: any) => new Promise( ( resolve, reject ) => {
                //this._initRequest();
                //this._initListeners( resolve, reject, file );
                //this._sendRequest( file );
                this._sendRequestByAxios( resolve, reject, file );
            } ) );
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) this.xhr.abort();
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr;
        // Note that your request may look different. It is up to you and your editor
        // integration to choose the right communication channel. This example uses
        // a POST request with JSON as a data structure but your configuration
        // could be different.
        xhr.open( 'POST', `${process.env.VUE_APP_BACKEND_URL}/api/Files?mode=ckeditor`, true );
        //xhr.setRequestHeader('Authorization', `Basic ${CloudFun.user.Token}`)
        xhr.responseType = 'json';
    }

    _sendRequestByAxios(resolve: any, reject: any, file: any) {

        const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;
        const data = new FormData();
        data.append('file', file);
        data.append('ProductID', '0');
        data.append('width', '0');
        data.append('upload', '0');

        model?.dispatch('image/insert', data)
          .then(function (response: any) {
            if(response.status) {
                resolve({default: `${baseUrl}/api/v1/Image/${response.payload.archiveID}`})
            } else {
                reject('圖片上傳失敗')
            }
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
        });
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve: any, reject: any, file: any) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;

        xhr.addEventListener('error', () => reject( genericErrorText ));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;

            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if ( !response || response.status !== 200 ) {
                return reject( response.message || genericErrorText );
            }

            //console.log(response.payload[0]);
            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            resolve({
                default: response.payload[0]
            });
        } );

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if ( xhr.upload ) {
            xhr.upload.addEventListener('progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    //Prepares the data and sends the request.
    _sendRequest(file: any) {
        // Prepare the form data.
        const data = new FormData();

        data.append('upload', file);

        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.

        // Send the request.
        this.xhr.send(data);
    }
}

export default function UploadAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new UploadAdapter(loader);
    };
}